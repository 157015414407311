html {
    height: 100%;
  }
  
  body {
    height: inherit;
    margin: 0;
    font-family: "Inter", "Helvetica", "Arial";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  html {
    overflow-y: scroll;
  }
  
  #root {
    height: inherit;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
    font-weight: 100;
    font-variation-settings: "wght" 100;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
    font-weight: 200;
    font-variation-settings: "wght" 200;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
    font-weight: 300;
    font-variation-settings: "wght" 300;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
    font-weight: 400;
    font-variation-settings: "wght" 400;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
    font-weight: 500;
    font-variation-settings: "wght" 500;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
    font-weight: 600;
    font-variation-settings: "wght" 600;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
    font-weight: 700;
    font-variation-settings: "wght" 700;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
    font-weight: 800;
    font-variation-settings: "wght" 800;
  }
  
  @font-face {
    font-family: "Inter";
    src: url("./fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
    font-weight: 900;
    font-variation-settings: "wght" 900;
  }
  