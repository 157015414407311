@import-normalize; /* bring in normalize.css styles */

.App {
  color: #162e3f;
}

#appContainer {
  height: inherit;
  display: flex;
  flex-direction: column;
}
